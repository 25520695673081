.chartWrapper {
    background-color: rgba(255,255,255,0.65);
    transition: background-color .5s, box-shadow .5s;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.05);
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
}
.chartWrapper:hover {
    background-color: rgba(255,255,255,0.95);
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.25);
}

.chartWrapper>p {
    font-size: 18px;
    font-weight: 700;
    margin: 20px 0 0 20px;
}

.chartWrapper>p>span {
    font-size: 16px;
    font-weight: normal;
    margin: 5px 0 0 20px;
}

.chartWrapperContent {
    height: 520px;
}

.chartWrapperItems {
    display: flex;
    flex-wrap: wrap;
    margin: 0 16px;
}

.noDataMessage {
    text-align: center;
    position: relative;
    top: 50%;
}